import React from "react";
import AboutInfo from "../AboutInfo";
import AboutStory from "../AboutStory";
import AboutProcess from "../AboutProcess";
import CommonBoxesWrap from "../CommonBoxesWrap";
import CommonCard from "../CommonCard";
import CommonMedia from "../CommonMedia";
import TwoColsCardWithoutLayer from "../TwoColsCardWithoutLayer";
import CarouselMediaBlock from "../CarouselMediaBlock";
import ContentGrid from "../ContentGrid";
import FacilitiesTabsBlock from "../FacilitiesTabsBlock";
import FacilitiesBlock from "../FacilitiesBlock";
import FindStore from "../FindStore";
import Footer from "../Footer";
import FourColumnCard from "../FourColumnCard";
import FullBleedFeature from "../FullBleedFeature";
import FullBleedBannerOne from "../FullBleedBannerOne";
import FullBleedBannerThree from "../FullBleedBannerThree";
import FullBleedBannerTwo from "../FullBleedBannerTwo";
import FullBleedBannerFour from "../FullBleedBannerFour";
import Gallery from "../Gallery";
import Header from "../Header";
import HeaderThrift from "../HeaderThrift";
import HeroCardLarge from "../HeroCardLarge";
import HeroCardLargeTwo from "../HeroCardLargeTwo";
import HeroCardMedium from "../HeroCardMedium";
import HeroCardMediumTwo from "../HeroCardMediumTwo";
import HeroCardSmall from "../HeroCardSmall";
import HeroCardWithoutImage from "../HeroCardWithoutImage";
import ImageCarousel from "../ImageCarousel";
import MosaicBlock from "../MosaicBlock";
import Page from "../Page";
import PageNotFound from "../PageNotFound";
import Pagination from "../Pagination";
import ProfileInfo from "../ProfileInfo";
import RelatedStoryBlock from "../RelatedStoryBlock";
import SliderBlock from "../SliderBlock";
import StepsBlock from "../StepsBlock";
import StoreLocationBlock from "../StoreLocationBlock";
import StoreLocatorView from "../StoreLocatorView";
import StoreNearBy from "../StoreNearBy";
import SmallContainer from "../SmallContainer";
import TeamBlock from "../TeamBlock";
import TeamGrid from "../TeamGrid";
import Story2ColumnsCard from "../Story2ColumnsCard";
import ThreeColsMedia from "../ThreeColsMedia";
import ThriftProudNav from "../ThriftProudNav/ThriftProudNav";
import TwoColsMedia from "../TwoColsMedia";
import TwoColumnCard from "../TwoColumnCard";
import TwoColumnCardSecond from "../TwoColumnCardSecond";
import TwoColumnVerticalList from "../TwoColumnVerticalList";
import TwoColumnHorizontalList from "../TwoColumnHorizontalList";
import TwoColumnVerticalListSec from "../TwoColumnVerticalListSecond";
import VideoModalBlock from "../VideoModalBlock";
import ContactUsForm from "../ContactUsForm";
import ContactBanner from "../ContactBanner";
import ContactInfo from "../ContactInfo";
import HolidayBanner from "../HolidayBanner";
import HeroCardSmallTwo from "../HeroCardSmallTwo";
import PintrestBoard from "../PintrestBoard";
import ContactUsCategories from "../ContactUsCategories";
import TextWithCta from "../TextWithCta";
import RichTextEditor from "../RichTextEditor";
import HorizontalRule from "../HorizontalRule";
import ContactSearchBanner from "../ContactSearchBanner";
import ImpactLeftImageWithText from "../ImpactLeftImageWithText";
import TwoColumnList from "../TwoColumnList";
import Testimonials from "../Testimonials";
import PercentageBox from "../PercentageBox";
import TwoColumnStructure from "../TwoColumnStructure";
import ImpactCycle from "../ImpactCycle";
import ImpactStats from "../ImpactStats";
import MenuLinksComponent from "../MenuLinksComponent";
import ImpactGraph from "../ImpactGraph";
import IconsBlock from "../IconsBlock"; 
import TwoColWithTopImage from "../TwoColWithTopImage"; //
import SingleColSquareTestimonial from "../SingleColSquareTestimonial"; 
import TwoColSquareTestimonial from "../TwoColSquareTestimonial"; 
import TrendsGraphicalInformation from "../TrendsGraphicalInformation";
import TwoColumnParagraph from "../TwoColumnParagraph";
import PrioritizeContent from "../PrioritizeContent";
import ImpactTimeline from "../ImpactTimeline";
import ThreeColumnVideo from "../ThreeColumnVideo";

interface ComposerMapType {
  [key: string]: React.ComponentType<any>;
}

const ComposerMap: ComposerMapType = {
  aboutProcess:AboutProcess,
  aboutStory:AboutStory,
  aboutInfo: AboutInfo,
  commonBoxesWrap: CommonBoxesWrap,
  commonCard: CommonCard,
  commonMedia: CommonMedia,
  lookbookCarouselImage: CarouselMediaBlock,
  contentGrid: ContentGrid,
  donarProfits: FacilitiesTabsBlock,
  facilitiesBlock: FacilitiesBlock,
  findAStore: FindStore,
  footer: Footer,
  fourColumnCard: FourColumnCard,
  fullBleedFeature: FullBleedFeature,
  fullBleedBanner1: FullBleedBannerOne,
  fullBleedBanner3: FullBleedBannerThree,
  fullBleedBanner2: FullBleedBannerTwo,
  fullBleedBanner4: FullBleedBannerFour,
  // fullBleedBannerFour: FullBleedBannerFour,
  gallery:Gallery,
  header: Header,
  headerThrift: HeaderThrift,
  heroCardLarge: HeroCardLarge,
  heroCardLargeTwo: HeroCardLargeTwo,
  heroCardMedium: HeroCardMedium,
  heroCardMediumTwo: HeroCardMediumTwo,
  heroCardSmall: HeroCardSmall,
  heroCardWithoutImage: HeroCardWithoutImage,
  mosaic: MosaicBlock,
  impactTimeline:ImpactTimeline,
  imageCarousel:ImageCarousel,
  impactLeftImageWithText:ImpactLeftImageWithText,
  impactCycle:ImpactCycle,
  impactStats:ImpactStats,
  impactGraph:ImpactGraph,
  iconsBlock:IconsBlock,
  menuLinksComponent:MenuLinksComponent,
  page:Page,
  pageNotFound: PageNotFound,
  pagination: Pagination,
  profileInfo: ProfileInfo,
  percentageBox:PercentageBox,
  prioritizeContent:PrioritizeContent,
  relatedStoryBlock: RelatedStoryBlock,
  sliderBlock: SliderBlock,
  step: StepsBlock,
  storeLocationBlock: StoreLocationBlock,
  storeLocatorView: StoreLocatorView,
  storeNearBy: StoreNearBy,
  smallContainer:SmallContainer,
  designers: TeamBlock,
  teamGrid: TeamGrid,
  story2ColumnsCard: Story2ColumnsCard,
  singleColSquareTestimonial:SingleColSquareTestimonial,
  columnVideoCard:ThreeColumnVideo,
  threeColsMedia: ThreeColsMedia,
  thriftProudNav: ThriftProudNav,
  twoColsMedia: TwoColsMedia,
  twoColumnList:TwoColumnList,
  twoColsCardWithoutLayer: TwoColsCardWithoutLayer,
  twoColumnCard: TwoColumnCard,
  twoColumnCardSecond: TwoColumnCardSecond,
  twoColumnVerticalList: TwoColumnVerticalList,
  twoColumnHorizontalList: TwoColumnHorizontalList,
  twoColumnVerticalListSec: TwoColumnVerticalListSec,
  twoColumnParagraph:TwoColumnParagraph,
  twoColumnStructureImpact:TwoColumnStructure,
  colWithTopImage:TwoColWithTopImage,
  twoColSquareTestimonial:TwoColSquareTestimonial,
  trendsGraphicalInformation:TrendsGraphicalInformation,
  videoModalSection: VideoModalBlock,
  contactUsForm: ContactUsForm,
  contactBanner: ContactBanner,
  contactInfo: ContactInfo,
  holidayBanner: HolidayBanner,
  heroCardSmallTwo: HeroCardSmallTwo,
  pintrestBoard: PintrestBoard,
  contactUsCategories: ContactUsCategories,
  textWithCta: TextWithCta,
  testimonials:Testimonials,
  richTextEditor: RichTextEditor,
  horizontalRule: HorizontalRule,
  contactSearchBanner: ContactSearchBanner,
};

interface ComposerType {
  componentName: string;
  data: any;
  dyanmodb_table: string;
  BodyBackgroundColor: string;
  status: string;
}

const ComponentComposer: React.FC<ComposerType> = ({
  componentName,
  data,
  dyanmodb_table,
  BodyBackgroundColor,
  status,
}) => {
  const Component = ComposerMap[componentName];

  return Component ? (
    <React.Fragment>
      {
        <style
          dangerouslySetInnerHTML={{
            __html:
              `body{ background-color:` +
              BodyBackgroundColor +
              `}
`,
          }}
        />
      }{" "}
      <Component dyanmodb_table={dyanmodb_table} {...data} />
    </React.Fragment>
  ) : null;
};

export default ComponentComposer;
