export default () => ({
  header: {
    site_logo: "http://45.40.138.118:9405/images/logo.svg",
    storeText: " FIND A STORE",
    storeTextMobile: " FIND A STORE",
    searchText: "Search",
    menuItems: [
      { title: "START A FUNDRAISER", link: "" },
      { title: "GIFT CARDS", link: "" },
      { title: "CAREERS", link: "" },
      { title: "ABOUT US", link: "/about" },
      { title: "CONTACT US", link: "/contact" },
    ],
    HeaderOptions: [
      { title: "SUPER SAVERS CLUB", link: "" },
      { title: "DONATE", link: "/donation" },
      { title: "THRIFT PROUD", link: "/thriftproud" },
    ],
  },

  footer: {
    LinkOptions: [
      { title: "SUPER SAVERS CLUB", link: "" },
      { title: "DONATE", link: "/donation" },
      { title: "THRIFT PROUD", link: "/thriftproud" },
    ],
    menuItems: [
      { title: "START A FUNDRAISER", link: "" },
      { title: "GIFT CARDS", link: "" },
      { title: "CAREERS", link: "" },
      { title: "ABOUT US", link: "" },
      { title: "CONTACT US", link: "" },
    ],
    join_ss_text: "Join the Super Savers Club®",
    join_ss_desc:
      "Earn points and rewards every time you thrift, enjoy exclusive email offers, and other special perks as a member",
    join_cta_text: "JOIN NOW",
  },
  social_links: {
    facebook: {
      icon_type: "font_awesome",
      url:
        "https://www.wheeloffortune.com/Widget/SocialLink?socialType=Facebook",
      icon_class: "fa fa-facebook",
      title: "Facebook",
    },
    instagram: {
      icon_type: "font_awesome",
      url:
        "https://www.wheeloffortune.com/Widget/SocialLink?socialType=Instagram",
      icon_class: "fa fa-instagram",
      title: "Instagram",
    },
    pinterest: {
      icon_type: "font_awesome",
      url:
        "https://www.wheeloffortune.com/Widget/SocialLink?socialType=Pinterest",
      icon_class: "fa fa-pinterest",
      title: "Pinterest",
    },
    twitter: {
      icon_type: "font_awesome",
      url:
        "https://www.wheeloffortune.com/Widget/SocialLink?socialType=Twitter",
      icon_class: "fa fa-twitter",
      title: "Twitter",
    },
    youtube: {
      icon_type: "font_awesome",
      url:
        "https://www.wheeloffortune.com/Widget/SocialLink?socialType=Youtube",
      icon_class: "fa fa-youtube",
      title: "Youtube",
    },
    giphy: {
      icon_type: "font_awesome",
      url: "https://www.wheeloffortune.com/Widget/SocialLink?socialType=Giphy",
      icon_class: "fa fa-giphy",
      title: "Giphy",
    },
  },
});
