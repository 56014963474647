import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {FindStore} from '../FindStore';
import { loadPageData } from "../../actions/cms";
import { RootState } from '../../reducers';
import client from '../../Client';
import { getLocale } from "../../api/cms/api";
import { addLangKeyPath } from "../../api/cms/api";
import ReactMarkdown from 'react-markdown';

const FullBleedBanner: React.FC<any> = (componentData) => {

  const currLocale = getLocale();
  const [title, setTitle] = useState('');
  const [titleFontColor, setTitleFontColor] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [mobileBackgroundImage, setMobileBackgroundImage] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionFontColor, setDescriptionFontColor] = useState('');
  const [ctaTitle, setCtaTitle] = useState('');
  const [ctaLink, setCtaLink] = useState('');
  const [ctaClass, setCtaClass] = useState('read-more-link');
  const [backgroundColor, setBackgroundColor] = useState('#007473');
  const [contentAlignment, setContentAlignment] = useState('');
  const [bannerSize, setBannerSize] = useState('');
  const [openLinkInNewTab, setOpenLinkInNewTab] = useState('');
  const [disclaimerText, setDisclaimerText] = useState('');
  const [kraftTexture, setKraftTexture] = useState(true);
  const [entryId, setEntryId] = useState('');
  const [ctaButtonColor, setCtaButtonColor] = useState('');
  const [ctaButtonTextColor, setCtaButtonTextColor] = useState('');
  const [ctaTextColor, setCtaTextColor] = useState('');
  const [ctaUnderlineColor, setCtaUnderlineColor] = useState('');
  const [ctaZigzaglineColor, setCtaZigzaglineColor] = useState('');
  const [hoverColor, setHoverColor] = useState('');
  const [backgroundPosition, setBackgroundPosition] = useState('');

  useEffect(() => {

    if (componentData.title != '' && componentData.title != undefined) {
      setTitle(componentData.title);
    }

    if (componentData.titleFontColor != '' && componentData.titleFontColor != undefined) {
      setTitleFontColor(componentData.titleFontColor);
    }

    if (componentData.backgroundImage != '' && componentData.backgroundImage != undefined) {
      let fileUrl = componentData.backgroundImage.fields.file.url;
      setBackgroundImage(fileUrl);
    }

    if (componentData.mobileBackgroundImage != '' && componentData.mobileBackgroundImage != undefined) {
      let fileUrl = componentData.mobileBackgroundImage.fields.file.url;
      setMobileBackgroundImage(fileUrl);
    }

    if (componentData.description != '' && componentData.description != undefined) {
      setDescription(componentData.description);
    }

    if (componentData.descriptionFontColor != '' && componentData.descriptionFontColor != undefined) {
      setDescriptionFontColor(componentData.descriptionFontColor);
    }

    if (componentData.backgroundColor != '' && componentData.backgroundColor != undefined) {
      setBackgroundColor(componentData.backgroundColor);
    }

    if (componentData.contentAlignment != '' && componentData.contentAlignment != undefined) {
      setContentAlignment(componentData.contentAlignment);
    }

    if (componentData.bannerSize != '' && componentData.bannerSize != undefined) {
      setBannerSize(componentData.bannerSize);
    }

    if (componentData.disclaimerText != '' && componentData.disclaimerText != undefined) {
      setDisclaimerText(componentData.disclaimerText);
    }

    if (componentData.entryId != "" && componentData.entryId != undefined) {
      setEntryId("content-"+componentData.entryId);
    }
    
     if (componentData.kraftTexture != '' && componentData.kraftTexture != undefined) {
      setKraftTexture(componentData.kraftTexture);
    }

    if (componentData.cta != '' && componentData.cta != undefined) {
      client.getEntry(componentData.cta.sys.id, {locale: currLocale})
      .then((entry : any) => {
        if (entry != '' && entry != undefined) {
          if (entry.fields.ctaType != '' && entry.fields.ctaType != undefined) {
            let ctaClassName = 'read-more-link';

            if (entry.fields.ctaType === 'Primary Button') {
              setCtaClass('btn btn-primary');
            }
            else if (entry.fields.ctaType === 'Secondary Button') {
              setCtaClass('btn btn-secondary');
            }
            else if (entry.fields.ctaType === 'Small Text CTA Black') {
              setCtaClass('small-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Small Text CTA White') {
              setCtaClass('small-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Large Text CTA Black') {
              setCtaClass('large-text-cta cta-black');
            }
            else if (entry.fields.ctaType === 'Large Text CTA White') {
              setCtaClass('large-text-cta cta-white');
            }
            else if (entry.fields.ctaType === 'Icon Text CTA') {
              setCtaClass('icon-text-cta');
            }
            else if (entry.fields.ctaType === 'Read More CTA') {
              setCtaClass('read-more-link');
            }
          }
          if (entry.fields.title != '' && entry.fields.title != undefined) {
            setCtaTitle(entry.fields.title);
          }
          if (entry.fields.alias != '' && entry.fields.alias != undefined) {
            setCtaLink(entry.fields.alias);
          }
          if (entry.fields.ctaTextColor != '' && entry.fields.ctaTextColor != undefined) {
            setCtaTextColor(entry.fields.ctaTextColor);
          }
          if (entry.fields.ctaZigzaglineColor != '' && entry.fields.ctaZigzaglineColor != undefined) {
            setCtaZigzaglineColor(entry.fields.ctaZigzaglineColor);
          }
          if (entry.fields.ctaUnderlineColor != '' && entry.fields.ctaUnderlineColor != undefined) {
            setCtaUnderlineColor(entry.fields.ctaUnderlineColor);
          }
          if (entry.fields.ctaButtonColor != '' && entry.fields.ctaButtonColor != undefined) {
            setCtaButtonColor(entry.fields.ctaButtonColor);
          }
          if (entry.fields.ctaButtonTextColor != '' && entry.fields.ctaButtonTextColor != undefined) {
            setCtaButtonTextColor(entry.fields.ctaButtonTextColor);
          }
          if (entry.fields.hoverColor != '' && entry.fields.hoverColor != undefined) {
            setHoverColor(entry.fields.hoverColor);
          }
          if (entry.fields.openLinkInNewTab != '' && entry.fields.openLinkInNewTab != undefined && entry.fields.openLinkInNewTab === true) {
            setOpenLinkInNewTab("_blank");
          }
        }
      })
      .catch(console.error)
    }

    if (componentData?.backgroundPosition) {
      setBackgroundPosition(componentData.backgroundPosition);
    }
  }, []);

  let contentAlign = "";
  if (contentAlignment == "Left") {
    contentAlign = "content-align-left";
  }
  else if (contentAlignment == "Center") {
    contentAlign = "content-align-center";
  }

  return (
    <>
      <div className={contentAlign ? "full-bleed-banner1 "+ contentAlign : (bannerSize? "full-bleed-banner1 "+ bannerSize.toLowerCase():"full-bleed-banner1")} style={{backgroundColor: backgroundColor}}>
        {backgroundImage? <div className="desktop-bg" style={{backgroundImage: "url("+backgroundImage+")"}}></div>: ""}
        {mobileBackgroundImage? <div className="mobile-bg" style={{backgroundImage: "url("+mobileBackgroundImage+")"}}></div>: ""}
        <style dangerouslySetInnerHTML=
          {{__html: `
            .large-text-cta.new.cta-white.${entryId} i,
            .large-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};            
            }

           .large-text-cta.new.cta-white.${entryId}:hover  i,
           .large-text-cta.new.cta-white.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

            .large-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

             .large-text-cta.new.cta-black.${entryId} i,
            .large-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover i,
           .large-text-cta.new.cta-black.${entryId}:hover {
                text-decoration: none;
                color:${hoverColor};
            }

             .large-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .large-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-black.${entryId} i,
            .small-text-cta.new.cta-black.${entryId} {
               color:${ctaTextColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover i,
            .small-text-cta.new.cta-black.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-black.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-black.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .small-text-cta.new.cta-white.${entryId} i,
            .small-text-cta.new.cta-white.${entryId} {
               color:${ctaTextColor};
            }
            .small-text-cta.new.cta-white.${entryId}:hover i,
            .small-text-cta.new.cta-white.${entryId}:hover {
              text-decoration: none;
               color:${hoverColor};
            }

             .small-text-cta.new.cta-white.${entryId} span {
              background: ${ctaUnderlineColor};
            }

            .small-text-cta.new.cta-white.${entryId}:hover svg path {
              stroke: ${ctaZigzaglineColor};
            }

            .btn.btn-primary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-primary.${entryId}:focus,
            .btn.btn-primary.${entryId}:focus:active,
            .btn.btn-primary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color: ${hoverColor};   
            }

            .btn.btn-primary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color: ${ctaButtonColor};
            }

            .btn.btn-primary.${entryId}:hover{
                 background:${hoverColor};
                 border-color: ${hoverColor};
            }

            .btn.btn-secondary.${entryId}:active {
              background:${ctaButtonColor} !important;
              border-color: ${ctaButtonColor} !important;
            }

            .btn.btn-secondary.${entryId}:focus,
            .btn.btn-secondary.${entryId}:focus:active,
            .btn.btn-secondary.${entryId}:visited{
                background:${hoverColor};
               color:${ctaButtonTextColor};
               border-color: ${hoverColor};   
            }

            .btn.btn-secondary.${entryId} {
                background:${ctaButtonColor};
               color:${ctaButtonTextColor};
               border-color: ${ctaButtonColor};
            }
            
             .btn.btn-secondary.${entryId}:hover{
                 background:${hoverColor};
                 border-color: ${hoverColor};
            }

            .read-more-link.${entryId} {
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            .read-more-link.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            }

            .icon-text-cta.${entryId},a{
              color: ${ctaTextColor};
              text-decoration-color: ${ctaUnderlineColor};
            }
            
            .icon-text-cta.${entryId}:hover {
              color: ${hoverColor};
              text-decoration-color: ${hoverColor};
            }       

            .full-bleed-banner1 .desktop-bg,
            .full-bleed-banner1 .mobile-bg {
              background-position: ${backgroundPosition};
            }

            `,
          }}
        />
        <div className="container">
          <div className="banner-info">
          {title ?(kraftTexture==true ? <h1 style={{color: titleFontColor}}> <span dangerouslySetInnerHTML={{__html:title}}></span></h1>:<h1>{title}</h1>): ""}
          
            {description ? (<div style={{color: descriptionFontColor}}><ReactMarkdown allowDangerousHtml source={description}></ReactMarkdown></div>): ""}
            {ctaTitle ? (
              <a className={ctaClass+" new"+" "+entryId} target={openLinkInNewTab} href={addLangKeyPath(ctaLink)}>{ctaTitle}
                  <i className="fa fa-caret-right" aria-hidden="true"></i>          
                  <span className="svg-outer">
                    <svg width="387" height="12" viewBox="0 0 387 12">
                      <defs>
                        <clipPath>
                          <rect data-name="Rectangle 1010" width="387" height="12" fill="#fff" stroke="#000" stroke-width="1"/>
                        </clipPath>
                      </defs>
                      <g data-name="Mask Group 62" clip-path="url(#clip-path)">
                        <path data-name="Path 2582" d="M358.965,496.543l9.128-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.128-4,9.129,4,9.128-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.128,4,9.129-4,9.127,4,9.126-4,9.128,4,9.128-4,9.126,4,9.128-4,9.128,4,9.127-4,9.128,4,9.128-4,9.128,4,9.129-4,9.128,4,9.13-4,9.128,4,9.13-4,9.128,4,9.129-4,9.129,4,9.129-4,9.129,4,9.13-4,9.129,4,9.129-4,9.129,4,9.13-4,9.13,4,9.131-4,9.13,4,9.13-4,9.128,4,9.129-4,9.13,4,9.131-4,9.13,4,9.13-4,9.131,4,9.132-4,9.129,4,9.133-4,9.133,4,9.134-4,9.134,4" transform="translate(-358.965 -488.543)" fill="none" stroke="#000" stroke-width="2"/>
                      </g>
                    </svg>          
                  </span>
                  <span>           
                  </span>
              </a>
              )
              : ""
            }
          </div>
        </div>
      </div>
       {disclaimerText ? <div className="container"><div className="col-lg-10 offset-lg-1 pb-10"><small><ReactMarkdown allowDangerousHtml source={disclaimerText} disallowedTypes={['paragraph']} unwrapDisallowed /></small></div></div> : ""}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
    fetchSiteData: () => dispatch(loadPageData())
});

const mapStateToProps = (state: RootState) => ({
    header: state.site.header,
});

FullBleedBanner.defaultProps = {
    fetchSiteData: () => {},
    header: {},
};

export { FullBleedBanner };

export default connect(mapStateToProps, mapDispatchToProps)(FullBleedBanner);
